/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { ChainId } from '@constants/CChains';
import { CloudFunctions } from '@constants/CCloudFunctions';
import { MetamaskError } from '@utils/errors';
import { ServerNetwork, ServerNetworkLabels } from '@utils/functions';
import Moralis from 'moralis-v1';
import { useMoralis, useChain } from 'react-moralis';
import {
  useGetServerNetworkQuery,
  useGetTransferTypeQuery,
} from '@store/api/utilApi';
import { userApi } from '@store/api/userApi';
import { ApiTag } from '@typescript/Api.enum';
import { useAppDispatch } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { hideLoginPopup } from '@store/slices/connectSlice';
import useNotification from './useNotification';

type Provider = 'metamask' | 'walletconnect';

const useUtils = () => {
  const {
    authenticate,
    isAuthenticated,
    logout,
    account,
    enableWeb3,
    isWeb3Enabled,
  } = useMoralis();
  const { notifyInfo, notifyError } = useNotification();

  const { chain, switchNetwork, chainId } = useChain();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [incompatibleChain, setIncompatibleChain] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { data: transferType } = useGetTransferTypeQuery({});

  const { data: serverNetwork } = useGetServerNetworkQuery({});

  const getBscAddressLink = (address: string) => {
    const bscScanUrl =
      serverNetwork === 'bsc'
        ? `https://bscscan.com/address`
        : 'https://testnet.bscscan.com/address';

    return `${bscScanUrl}/${address}`;
  };

  const getBscTxLink = (hash: string) => {
    const bscScanUrl =
      serverNetwork === 'bsc'
        ? `https://bscscan.com/tx`
        : 'https://testnet.bscscan.com/tx';

    return `${bscScanUrl}/${hash}`;
  };

  const moralisAuth = async () => {
    if (!account) {
      throw new Error(
        'Connecting to chain failed, no connected account was found',
      );
    }

    if (!chainId) {
      throw new Error(
        'Connecting to chain failed, no connected chain was found',
      );
    }
    const { message } = await Moralis.Cloud.run(
      CloudFunctions.MORALIS_REQUEST_MESSAGE,
      {
        address: account,
        chain: parseInt(chainId, 16),
        statement:
          // eslint-disable-next-line max-len
          'Welcome to Sark Family Office! Please endorse this message to gain access and agree to our terms of service: https://sarkfamilyoffice.com/terms-and-conditions/',
      },
    );

    await authenticate({
      signingMessage: message,
      throwOnError: true,
    });
  };

  const switchCorrectChain = async (cancelledSwitchNetwork?: boolean) => {
    try {
      if (
        serverNetwork === ServerNetwork.BSC &&
        chain?.chainId !== ChainId.BSC
      ) {
        await switchNetwork(ChainId.BSC);
      }
      if (
        serverNetwork === ServerNetwork.BSC_TESTNET &&
        chain?.chainId !== ChainId.BSC_TEST_NET
      ) {
        await switchNetwork(ChainId.BSC_TEST_NET);
      }
    } catch (error) {
      // Handle failed switch network
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (cancelledSwitchNetwork) {
        cancelledSwitchNetwork = true;
      }

      switch ((error as MetamaskError)?.code) {
        case -32002: {
          notifyError(
            'You already have a pending request. Check your Metamask!',
          );
          break;
        }
        case 4001: {
          notifyError('Metamask switch network cancelled.');
          break;
        }
        case -32603: {
          notifyError('You do not have BNB chain added in Metamask.');
          break;
        }
        default: {
          notifyError((error as MetamaskError).message);
          throw new Error(
            `${(error as MetamaskError).code}: ${
              (error as MetamaskError).message
            }`,
          );
        }
      }
    }
  };

  const login = async (provider: Provider) => {
    if (!isWeb3Enabled) {
      await enableWeb3();
    }

    if (provider === 'metamask' && isWeb3Enabled) {
      const cancelledSwitchNetwork = false;
      await switchCorrectChain(cancelledSwitchNetwork);

      // Handle failed login to platform
      if (!cancelledSwitchNetwork) {
        try {
          // await enableWeb3();
          await moralisAuth();
          dispatch(userApi.util.invalidateTags([ApiTag.USER]));

          dispatch(hideLoginPopup());
          // navigate('/');
        } catch (error) {
          if (
            (error as MetamaskError).message.includes(
              'Timeout may have exceeded',
            )
          ) {
            notifyError(
              'Too much time passed for accepting Metamask signin. Please try again.',
            );
            return;
          }

          if (
            (error as MetamaskError).message.includes(
              'chainId must be one of the following',
            )
          ) {
            notifyError('A window refresh was needed. Try signing in again.');
            navigate('.', { replace: true });
            return;
          }

          switch ((error as MetamaskError)?.code) {
            case -32002: {
              notifyError(
                'You already have a pending request. Check your Metamask!',
              );
              return;
            }
            case 4001: {
              notifyError('Login Signature rejected!');
              return;
            }
            default: {
              notifyError((error as MetamaskError).message);
              throw new Error(
                `${(error as MetamaskError).code}: ${
                  (error as MetamaskError).message
                }`,
              );
            }
          }
        }
      }
    }
  };

  const currentNetworkLabel =
    serverNetwork &&
    (ServerNetworkLabels as Record<string, string>)[
      serverNetwork.toUpperCase()
    ];

  const logOut = async (disableNavigate = false) => {
    await logout();
    localStorage.clear();
    dispatch(userApi.util.invalidateTags([ApiTag.USER]));
    notifyInfo('You have been disconnected!');
    await enableWeb3();

    // fix for the ones that logout and login in same window
    if (!disableNavigate) {
      navigate('/projects', { replace: true });
    }
  };

  const verifyCorrectChain = async (chainID: string | null) => {
    if (serverNetwork === ServerNetwork.BSC) {
      if (chainID !== ChainId.BSC) {
        setIncompatibleChain(true);
      } else {
        setIncompatibleChain(false);
      }
    }
    if (serverNetwork === ServerNetwork.BSC_TESTNET) {
      if (chainID && chainID !== ChainId.BSC_TEST_NET) {
        // notifyWarning('Incompatible network!');
        setIncompatibleChain(true);
      } else {
        setIncompatibleChain(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (chainId && serverNetwork) {
      verifyCorrectChain(chainId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, isAuthenticated, serverNetwork]);

  return {
    serverNetwork,
    incompatibleChain,
    login,
    logOut,
    isLoading,
    transferType,
    getBscAddressLink,
    getBscTxLink,
    currentNetworkLabel,
    switchCorrectChain,
  };
};

export default useUtils;
