import {
  useGetWhitelistsQuery,
  useUpdateWhitelistsMutation,
} from '@store/api/whitelistsApi';

const useWhitelists = () => {
  const [updateWhitelists] = useUpdateWhitelistsMutation();

  return { updateWhitelists };
};

export default useWhitelists;
